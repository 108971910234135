import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../common/BreadCrumb";
import {
  message,
  Input,
  Button,
  Form,
  Space,
  Tooltip,
  Table,
} from "antd";
import "./style.css";
import {
  DeleteOutlined,
  EyeOutlined
} from "@ant-design/icons";
import {
  deleteSite,
  getAllSite,
  insertSite,
} from "../../helpers/helper";
import { Drawer } from "antd";
import { number } from "yup";
import moment from "moment";
import { useHistory } from "react-router-dom";
const Categories = () => {
  document.title = "Management Categories";
  const history = useHistory();
  const [form] = Form.useForm();
  const [listSite, setListSite] = useState([]);
  const [isShow, setIsShow] = useState(true);
  const [visibleForm, setVisibleForm] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState("");
  
  
  useEffect(() => {
    async function fetchData() {
      const dataRes = await getAllData();
      console.log(dataRes)
      setListSite(dataRes);
    }
    fetchData();
  }, []);
  const getAllData = async () => {
    let dataRes = await getAllSite();
    const data =
      dataRes?.data &&
      dataRes?.data.length > 0 &&
      dataRes?.data.map((item) => {
        return {
          _id: item._id,
          name: item.name,
          keyword: item.keyword,
          totalDayCheck: item.totalDayCheck,
          createdAt: item.createdAt,
          dataCheck: item.dataCheck
        };
      });
    return dataRes?.data ? data : [];
  };

  const onFinish = async (data) => {
    const dataReq = {
      name: data.name,
      keyword: data.keyword,
      totalDayCheck: data.totalDayCheck,
    };
    if (!data._id) {
      //Save
      const dataRes = await insertSite(dataReq);
      if (dataRes.success) {
        message.success(`Lưu thành công! ${dataRes.message}`);
        setVisibleForm(false);
        handleCloseDrawer();
      } else {
        message.error(`Save Failed! ${dataRes.message}`);
      }
    } else {
      // //Update
      // const dataRes = await updateSite(data._id, dataReq);
      // if (dataRes.success) {
      //   message.success(`Lưu thành công! ${dataRes.message}`);
      //   handleCloseDrawer();
      // } else {
      //   message.error(`Save Failed! ${dataRes.message}`);
      // }
    }
    const dataRes = await getAllData();
    setListSite(dataRes);
    form.resetFields();
  };
  const handleRefresh = async () => {
    form.resetFields();
    const dataRes = await getAllData();
    setListSite(dataRes);
  };


  // const onEdit = async (key) => {
  //   const dataEdit = listSite.filter((item) => item.key === key);

  //   setIsShow(dataEdit[0].isShow);
  //   form.setFieldsValue({
  //     SiteName: dataEdit[0].SiteName,
  //     SiteSlug: dataEdit[0].SiteSlug,
  //     SiteOrder: dataEdit[0].SiteOrder,
  //     type: dataEdit[0].type,
  //     _id: dataEdit[0].key,
  //     isShow: dataEdit[0].isShow,
  //     canonical: dataEdit[0].canonical,
  //     faq: dataEdit[0].faq,
  //     seo_title: dataEdit[0].seo_title

  //   });
  //   setDrawerTitle("Sửa danh mục");
  //   showDrawer();
  // };

  const onDelete = async (key) => {
    if(window.confirm("Bạn có chắc muốn xóa không?")){
      const dataRes = await deleteSite(key);
      dataRes.success
        ? message.success(`Xóa thành công! ${dataRes.message}`)
        : message.error(`Xóa thất bại! ${dataRes.message}`);
  
      handleRefresh();
    }
  };


  const handleNewSite = () => {

    setDrawerTitle("Thêm Danh mục");
    showDrawer();
    form.resetFields();
  };
  const onClose = () => {
    setVisibleForm(false);
  };

  const columns = [
    {
      title: "Site url",
      dataIndex: "name",
    },
    {
      title: "keyword",
      dataIndex: "keyword",
    },
    {
      title: "Tổng sớ ngày kiểm tra",
      dataIndex: "totalDayCheck",
    },
    {
      title: "Lần kiểm tra gần nhất",
      render:(_, record) => {
        console.log(record.dataCheck)
        const item = record.dataCheck.length > 0?record.dataCheck[record.dataCheck.length - 1]: null;
        if(!item){
          return <></>
        }else {
          return <>
          <div>desktop:{item.desktop?<span style={{color : "green", fontWeight: 700}}>{item.desktop}</span>: "N/A"} </div>
          <div>mobile:{item.mobile?<span style={{color : "green", fontWeight: 700}}>{item.mobile}</span>: "N/A"} </div>
          </>
        }
        
      }
    },
    {
      title: "Ngày tạo kiểm tra",
      render:(_, record) => {
        return <span>{moment(record.createdAt).format('L')}</span>
      }
    },
    {
      title: "Hành động",
      dataIndex: "",
      render: (_, record) =>
        listSite.length >= 1 ? (
          <Space>
            <Tooltip title="Xem data">
              <Button
                type="primary"
                shape="circle"
                icon={<EyeOutlined />}
                size="small"
                onClick={() => window.open("/view-detail/" + record._id)}
              />
            </Tooltip>
            <Tooltip title="Xóa">
              <Button
                type="danger"
                shape="circle"
                icon={<DeleteOutlined />}
                size="small"
                onClick={() => onDelete(record._id)}
              />
            </Tooltip>
          </Space>
        ) : null,
    },
  ];

  const showDrawer = () => {
    setVisibleForm(true);
  };

  const handleCloseDrawer = () => {
    setVisibleForm(false);
    form.resetFields();
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Danh mục" pageTitle="Quản lí danh mục" />

          <div>
            <Drawer
              title={drawerTitle}
              placement={"right"}
              width={"70%"}
              onClose={onClose}
              open={visibleForm}
              bodyStyle={{
                paddingBottom: 80,
              }}
              style={{ marginTop: "70px" }}
            >
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
              >
                <Row>
                  <Col hidden={true}>
                    <Form.Item name="_id" label="Id">
                      <Input name="_id"/>
                    </Form.Item>
                  </Col>
                  <Form.Item
                    name="name"
                    label="Tên Site (bỏ https, http) vd: f8bet.com"
                    rules={[
                      {
                        required: true,
                        message: "Please input url name!",
                      },
                      {
                        type: "string",
                        min: 1,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter url"
                      name="name"
                      allowClear={true}
                    />
                  </Form.Item>
                  <Form.Item
                    name="keyword"
                    label="Keyword"
                    rules={[
                      {
                        required: true,
                        message: "Please input keyword",
                      },
                      {
                        type: "string",
                        min: 1,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter keyword"
                      name="keyword"
                      allowClear={true}
                    />
                  </Form.Item>
                  <Form.Item
                    name="totalDayCheck"
                    label="Số ngày kiểm tra"
                    rules={[
                      {
                        required: true,
                        message: "Please input ",
                      },
                      {
                        type: number,
                        min: 1,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter keyword"
                      name="totalDayCheck"
                      allowClear={true}
                    />
                  </Form.Item>
                  {/* <Form.Item name="type" label="Loại danh mục">
                    <Input
                      placeholder="Enter string of Site type"
                      name="type"
                      allowClear={true}
                      type="string"
                    />
                  </Form.Item> */}
                  {/* {content != null &&                 
                    <Form.Item label="Nội dung">
                    <Editor
                      value={content}
                      onChange={(v) => editorContentRef.current = v}
                      refId={nameRefId}
                    />
                  </Form.Item>} */}
                </Row>
                <Form.Item className="mt-3">
                  <Space>
                    <Button type="primary" htmlType="submit">
                      Lưu
                    </Button>
                    <Button
                      type="info"
                      htmlType="button"
                      onClick={() => handleRefresh()}
                    >
                      Làm mới trang
                    </Button>
                    <Button type="danger" onClick={handleCloseDrawer}>
                      Đóng
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Drawer>
          </div>
          <Row>
            <Col xs={12}>
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
              >
                <Col hidden={true}>
                  <Form.Item name="id" label="Id">
                    <Input name="id" />
                  </Form.Item>
                </Col>
                <Form.Item className="mt-3">
                  <Space>
                    <Button type="primary" onClick={handleNewSite}>
                      Tạo mới
                    </Button>

                    <Button
                      type="primary"
                      htmlType="button"
                      onClick={() => handleRefresh()}
                    >
                       Làm mới trang
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Col>
          </Row>

          <div>
            <Table columns={columns} dataSource={listSite} />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Categories;