import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const Navdata = () => {
  const history = useHistory();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isPosts, setIsPost] = useState(false);
  const [isVideos, setIsVideos] = useState(false);
  const [isRanking, setIsCategory] = useState(false);
  const [isConfigs, setIsConfigs] = useState(false);
  const [isArticles, setIsArticles] = useState(false);
  const [isFootball, setFootball] = useState(false);
  const [isAuthentications, setAuthentications] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Posts") {
      setIsPost(false);
    }
    if (iscurrentState !== "Configs") {
      setIsConfigs(false);
    }
    if (iscurrentState !== "Football") {
      setIsArticles(false);
    }
  }, [history, iscurrentState, isDashboard, isPosts, isConfigs, isFootball]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    // {
    //   id: "posts",
    //   label: "Bài viết",
    //   icon: "ri-dashboard-2-line",
    //   link: "/#",
    //   stateVariables: isPosts,
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsPost(!isPosts);
    //     setIscurrentState("Posts");
    //     updateIconSidebar(e);
    //   },
    //   subItems: [
    //     {
    //       id: "posts",
    //       label: "Danh sách bài viết",
    //       link: "/posts",
    //       parentId: "posts",
    //     },
    //     {
    //       id: "tags",
    //       label: "Tags",
    //       link: "/tags",
    //       parentId: "post",
    //     },
    //   ],
    // },
    {
      id: "Ranking",
      label: "Ranking",
      icon: "ri-dashboard-2-line",
      link: "/#",
      stateVariables: isRanking,
      click: function (e) {
        e.preventDefault();
        setIsCategory(!isRanking);
        setIscurrentState("Ranking");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "checkrank",
          label: "Kiểm tra rank",
          link: "/check-rank",
        },
        {
          id: "listsite",
          label: "Danh sách web",
          link: "/listsite",
        },
      ],
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
